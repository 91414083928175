.bg_img{
    
}
.slogan{
    font-size: 65px;
    font-family: '';
    text-align: center;
    color:#fff;
    padding:30px 0 0px 0;
}
.points div{
    text-align: center;
    font-size: 20px;
}
.apartment_icon{
    width:100px;
    line-height: auto;
    position: relative;
    top:20px;
    margin-right: 20px;
}
.points{
    color:#6f80eb;
    margin:50px 0;
}



/*new*/
.logo_animate{

}

.logo_animate h1 {
    background: -webkit-linear-gradient(135deg,
            #0eaf6d,
            #ff6ac6 25%,
            #147b96 50%,
            #e6d205 55%,
            #2cc4e0 60%,
            #8b2ce0 80%,
            #ff6384 95%,
            #08dfb4);
    /* 文字颜色填充设置为透明 */
    -webkit-text-fill-color: transparent;
    /* 背景裁剪，即让文字使用背景色 */
    -webkit-background-clip: text;
    /* 背景图放大一下，看着柔和一些 */
    -webkit-background-size: 200% 100%;
    /* 应用动画flowCss 12秒速度 无限循环 线性匀速动画*/
    -webkit-animation: flowCss 2s infinite linear;
    text-align: center;
    font-size: 60px;
}

@-webkit-keyframes flowCss {
    0% {
        /* 移动背景位置 */
        background-position: 0 0;
    }

    100% {
        background-position: -400% 0;
    }
}

h1:hover {
    -webkit-animation: flowCss 4s infinite linear;
}