.tour_bg_box{
    position: relative;
}
.tour_bg{
    width:100%;
    position: relative;
}
.tour_bg_step_1{
    position: absolute;

    width:50px;
    height:50px;
    z-index: 2;
    top: 13%;
    right:21%;
}
.tour_bg_step_2{
    position: absolute;

        width: 50px;
        height: 50px;
        z-index: 2;
        top: 33%;
        right: 8%;
}
.tour_bg_step_3{
    position: absolute;

        width: 50px;
        height: 50px;
        z-index: 2;
        top: 55%;
        right: 12%;
}
.tour_bg_step_4 {
    position: absolute;

    width: 50px;
    height: 50px;
    z-index: 2;
    top: 35%;
    right: 56%;
}
.tourButton{
    position: absolute;
    top:40%;
    right:50%;
    width:200px;
    height:40px;
    font-size: 20px;
    margin-right: -100px;
    color:#c5af84;
}