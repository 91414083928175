/*8大痛点*/
.icon-box {
    text-align: center;
    font-size: 18px;
    color: rgba(51, 51, 51, 1);
    margin-top: 20px;
    cursor: pointer;
}

.home-icon {
    width: 80px;
    height: 80px;
    background-position: 0 0;
    display: inline-block;
}

.home-icon-1 {
    background: url('../../images/tongdian/1.png');
    background-size: cover;
}

.home-icon-2 {
    background: url('../../images/tongdian/2.png');
    background-size: cover;
}

.home-icon-3 {
    background: url('../../images/tongdian/3.png');
    background-size: cover;
}

.home-icon-4 {
    background: url('../../images/tongdian/4.png');
    background-size: cover;
}

.home-icon-5 {
    background: url('../../images/tongdian/5.png');
    background-size: cover;
}

.home-icon-6 {
    background: url('../../images/tongdian/6.png');
    background-size: cover;
}

.home-icon-7 {
    background: url('../../images/tongdian/7.png');
    background-size: cover;
}


.icon-box:hover .home-icon {
    animation: homeIcon 0.5s;
    animation-fill-mode: forwards;
}

@keyframes homeIcon {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 0 -80px;
    }
}

.vs-box {
    border: 1px solid rgba(126, 132, 143, 0.1);
    padding: 0 !important;
    text-align: center;
    position: relative;
    z-index: 2;
}

.vs-box:hover {
    animation: activeVsBox 0.5s;
    animation-fill-mode: forwards;
}

@keyframes activeVsBox {
    from {
        top: 0;
    }

    to {
        top: -20px
    }
}

.vs-box .vs-box-title {
    font-size: 20px;
    color: rgba(126, 132, 143, 1);
    height: 57px;
    line-height: 57px;
    background: rgba(126, 132, 143, 0.2);
}

.vs-box .vs-box-item {
    padding: 20px 40px;
    height: 80px;
    border-top: 1px solid rgba(126, 132, 143, 0.2);
    background: rgba(126, 132, 143, 0.02);
}

.vs-box .vs-box-item.vs-box-item-blue {
    background: rgba(65, 170, 255, 0.06);
    border-top: 1px solid rgba(126, 132, 143, 0.1);
    color: rgba(65, 170, 255, 1);
}

.bada-box {
    box-shadow: 0px 4px 10px 4px rgba(50, 121, 178, 0.06);
    border-radius: 8px;
    text-align: center;
    color: rgba(51, 51, 51, 1);
}

.guanlitab {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 10px 4px rgba(50, 121, 178, 0.06);
    font-size: 18px;
    color: rgba(51, 51, 51, 1);
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
}

.guanlitab img {
    margin: 0 20px;
}

.guanlitab span {
    display: none;
}

.guanlitab.active span {
    width: 40px;
    height: 8px;
    background: rgba(65, 170, 255, 1);
    border-radius: 4px;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -20px;
}

.coverInfo {
    position: absolute;
    top: 0;
    width: 93.6%;
    height: 80%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(15, 15, 15, 0) 100%);
    color: #fff;

    border-radius: 5px;
    display: none;
}

.coverInfoText {
    padding: 20px;
}

.hezuoBox:hover .coverInfo {
    display: block;
}

.toast {}

.toast .toastMain {
    width: 300px;
    height: 200px;
    position: fixed;
    z-index: 200;
    top: 30%;
    left: 50%;
    margin-left: -150px;
    background: #fff;
    text-align: center;
}

.toast .toastMask {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
}

.ant-card-cover img {
    border-radius: 10px;
}