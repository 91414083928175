@font-face {
    font-family: 'mifont';
    src: url('../../images/tianzi/MiSans-Regular.ttf');
}
body{
    
}
.pointer-box{
    background:url('../../images/tianzi/bca4bf947eed0f3d8e74a7f2046ee0a8.jpeg') no-repeat center center;
    background-size: cover;
    padding:80px;
}
.pointer-box .pointer{
    font-weight: 700;
    color:#fff;
    font-size: 16px;
    padding:60px 60px;
    font-family: mifont;
}
.pointer-box .pointer div:first-child{
    font-size: 24px;
    margin-bottom: 20px;
}
.pointer-box .pointer div:last-child{
    padding:0 80px 0 0;
}