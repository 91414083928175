/*cowork*/


.cowork_bg {}
.cowork_solgan_box{
    padding:100px 0 10px 0;
}
.cowork_solgan{
    line-height: 1.2;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(90.97deg, #ddc405 44.25%, #000000 84.28%);
        font-size: 60px;
        margin-bottom: 20px;
    text-align: center;
}

.cowork_title {
    font-size: 28px;
    
    color: #000;
    margin: 40px 0 20px 0;
}

.cowork_title span {
    margin-right: 10px;
    cursor: pointer;
}
.activeIndex{
    color:#c5af84;
}
.cowork_name{
    font-size: 28px;
    
    -webkit-text-fill-color: transparent;
        background: linear-gradient(135deg, #c5af84, #000000);
        -webkit-background-clip: text;
        font-weight: 400;
}
.cowork_location{
    font-size: 14px;
        color: #000;
        margin: 15px 0;
}
.cowork_desc{
    font-size: 14px;
    color:#000;
    margin-top: 20px;
}
.cowork_products_list{
    margin:15px 0;
}
.cowork_products{
    font-size: 14px;
        color: #000;
        padding:10px 0;
}
.cowork_contact_button{
    background:#c5af84;
    border:none;
    color:#fff;
}

.cowork_steps_box {
    margin: 10px 0;
}

.cowork_steps_box>div {
    background: #fff;
    padding:10px;
}

.cowork_steps_box>div>h3 {
    text-align: center;
    font-size: 20px;
    color:#c5af84;
}

.cowork_steps_box>div>div {
    color: rgba(0, 0, 0, .3)
}
.cowork_images{
    border:5px solid rgb(255 243 219);
    cursor: pointer;
}
.cowork_images:hover {
    border: 5px solid #c5af84;
}

.slide_box{
    /* max-height: 800px; */
    overflow-y: hidden;
}
.slide_box img{
    width:100%;
}
.cowork_nametag{
    position: relative;
    top:-7px;
    left:10px;
}



.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}




.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cowork_box{
    background: rgb(251 251 251);
    border-radius: 5px;
    overflow:hidden;
    margin-top:60px;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after,.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after{
    color:#e3d2b1;
    background:#c5af84;
    padding:5px;
    font-size: 20px !important;
    border-radius: 2px;
}
.swiper-pagination-bullet, .swiper-pagination-bullet-active{
    background:#c5af84 !important;
}

.detail_mobile_screen{
    display: none;
    margin-top:20px;
}

/* 针对大屏幕设备的样式 */
@media only screen and (min-width: 960px) {


}

/* 针对平板设备的样式 */
@media only screen and (min-width: 479px) and (max-width: 1024px) {


    .cowork_name {
        font-size: 20px;
    }

    .cowork_nametag {
        top: -3px;
        left: 10px;
    }
        .cowork_solgan_box{
            padding:20px 0;
        }
                .cowork_solgan {
                    font-size: 30px;
                }
    .detail_big_screen{
        display: none;
    }
        .detail_mobile_screen {
            display: block;
        }
}

/* 针对手机设备的样式 */
@media only screen and (max-width: 479px) {



    .cowork_name {
        font-size: 20px;
    }

    .cowork_nametag {
        top: -3px;
        left: 10px;
    }
        .cowork_solgan_box {
            padding: 20px 0;
        }
        .cowork_solgan{
            font-size: 30px;
        }
                .detail_big_screen {
                    display: none;
                }
                                .detail_mobile_screen {
                                    display: block;
                                }
}